.bill-create-tabel-row input:disabled{

        background-color: #3c3c3c;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


input[type=number] {
  -moz-appearance: textfield;
}


.css-r3jkob-MuiButtonBase-root-MuiMenuItem-root-RaMenuItemLink-root.RaMenuItemLink-active {
  color: #673ab7 !important;
  font-weight: bold;  
}
.css-1gf0rvv-MuiButtonBase-root-MuiMenuItem-root-RaMenuItemLink-root.RaMenuItemLink-active {
  color: #630bff !important;
  font-weight: bold;
}